import React from "react";
import { Link } from "react-router-dom";
import Social from "../../../../components/social/Social";
import AboutFour from "../../../../components/about/AboutFour";
import CallToActionThree from "../../../../components/call-to-action/CallToActionThree";
import { GoDotFill } from "react-icons/go";
import {aboutBg, retailer, Prod1, Prod2, Prod3, Prod4, seamless, identity, kyc, realtime, digitalId, signature, comprehensive, customeAuthentication, sumart_money, Family_Mobile, silent, parent, child, seniors} from "../../pages/ImagePath"



const FamilyBanking = () => {
  return (
    <div className="main-page-wrapper p0">
 
 <div className="container-fluid mt-150 ps-0 pe-0">
      <div   style={{height:'500px', width:'100%', position:'relative', zIndex:2}}>
         <img src={retailer} alt=""  style={{width:'100%', height:'350px'}}  />
         <div className="position-absolute" style={{marginTop:'4%', marginLeft:'4%', zIndex:5}}>
         <h2 className="ps-0 fsm-bold  text-danger text-center" >Mobeix Family Banking</h2>
         <h4 className="text-primary ">Empower Your Family with Smart Banking Solutions</h4>
         </div>

      </div>

     
     </div>

      {/* 	=============================================
				Fancy Hero Three
			============================================== */}
      <div className="fancy-hero-three bg-transparent">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h3 className="text-dark fw-bold pb-2"   >Deliver Next-Generation Banking Solutions, Today </h3>
                <p className="text-dark text-justify" style={{textAlign:'justify'}}>
                When you combine the fast pace of the family life in today with the availability of technology, managing a family’s finances is challenging and fragmented. Parents want to teach social and financial responsibility alongside of giving their children an introduction to financial freedom to make responsible spending decisions. It can be difficult to find the right technology that provides appropriate oversight for parents with ease of use for youth. 
                </p>
          
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>
      {/* /.fancy-hero-three */}
    


      {/* 	=============================================
				Fancy Feature Seven
			==============================================  */}
      <div className="fancy-feature-seven">
        {/* ---------------------------------------------------- */}
      <div className="container-fluid position-relative pt-3 pb-4 bg-primary " style={{height:'60vh'}}>
      <div className="row p-3">

        <div className="col-lg-7 col-12 ">
         <h4 className="text-white fw-bold">Empower every generation with smart money management </h4>
         <p className="text-white pb-2 pt-2" style={{textAlign:'justify'}}>
         Mobeix Family Banking (MFB) is designed to bring the convenience and innovation of digital banking to the entire family. Offering a range of innovative and comprehensive financial management tools, MFB allows parents to monitor and manage their children’s spending, set savings goals, customise spending limits, receive real-time transaction alerts and facilitate allowances, all from a single, intuitive platform. With features that promote financial literacy and responsibility, MFB empowers families to achieve their financial goals together while ensuring a secure and seamless banking experience. 
                 </p>
        </div>
        <div className="col-lg-5 col-12">
       
        <div className="illustration-holder">
                    <img src={ sumart_money} alt="illustrator" style={{objectFit:'fill', height:'80%',width:'80%'}} />
               
                  </div>
        </div>
      </div>
    </div>
    {/* --------------------------------------------------- */}
        <div className="container mt-5">

          <div className="title-style-two text-center mb-30 md-mb-30">
     
            <h3 className="fw-bold pb-0 mb-0">
            Key Tenets of Mobeix Family Banking Product
              <span>
            
                <img src="images/shape/line-shape-8.svg" alt="line shape" />
              </span>{" "}
             
            </h3>
          </div>
          {/* /.title-style-two */}

          <div className="block-wrapper">
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-12 col-md-12 m-auto "
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder d-flex  justify-content-center">
                    <img src={Family_Mobile} alt="illustrator" style={{objectFit:'fill', height:'50%',width:'50%'}} />
                    
                  </div>
           
                </div>
            
              </div>
            </div>
     


   
            {/* /.block-style-nine */}
          </div>
        </div>

            {/* ---------------------------------------------------- */}
      <div className="container-fluid position-relative pt-3 pb-4 bg-primary " style={{height:'60vh'}}>
      <div className="row p-3">
      <div className="col-lg-5 col-12">
       
       <div className="illustration-holder p-3">
                <img src={silent} alt="illustrator" style={{objectFit:'fill', height:'60%',width:'70%'}} />
           
              </div>
      </div>
        <div className="col-lg-7 col-12  ">
         <h4 className="text-white fw-bold">Empower every generation with smart money management </h4>
         <p className="text-white pb-2 pt-2" style={{textAlign:'justify'}}>
         Mobeix Family Banking (MFB) is designed to bring the convenience and innovation of digital banking to the entire family. Offering a range of innovative and comprehensive financial management tools, MFB allows parents to monitor and manage their children’s spending, set savings goals, customise spending limits, receive real-time transaction alerts and facilitate allowances, all from a single, intuitive platform. With features that promote financial literacy and responsibility, MFB empowers families to achieve their financial goals together while ensuring a secure and seamless banking experience. 
                 </p>
        </div>
 
      </div>
    </div>

     
            {/* /.block-style-nine */}
            <div className="block-wrapper p-4 mt-4">
            <div className="block-style-nine">
            <h3 className="text-primary text-center fw-bold pb-1">Key Functionalities </h3>
              <div className="row align-items-center">
          
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder d-flex justify-content-center">
                    <img src={parent} alt="illustrator" />
                    {/* <img src="images/assets/ils_07.svg" alt="illustrator" /> */}
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                   
                    <h4 className="text-primary fw-bold pb-2">Parent </h4>
               
                     <div className="d-flex justify-content-between"> 
                      <ul >
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Card Controls  </li>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Child goals </li>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Fund Account/Send Money  </li>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Child Spending Overview </li>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Registration </li>
                      </ul>
                      <ul>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Subscription Plans  </li>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Security</li>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Notifications</li>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Task Management </li>
                      
                      </ul>
                     </div>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            </div>

            {/* ----------------------------------child */}
            <div className="block-wrapper p-4 mt-4">
            <div className="block-style-nine">

              <div className="row align-items-center">
              <div
                  className="col-lg-5 order-lg-last"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                   
                    <h4 className="text-primary fw-bold pb-2">Child </h4>
               
                     <div className="d-flex justify-content-between"> 
                      <ul >
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Registration   </li>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Send/Request Money  </li>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Card Controls   </li>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Earning  </li>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Spending Analysis  </li>
                      </ul>
                      <ul>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Goal Setting   </li>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Notifications </li>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Security  </li>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Personalisation  </li>
                      
                      </ul>
                     </div>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-first"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder d-flex justify-content-center">
                    <img src={child} alt="illustrator" />
                 
                  </div>
                  {/* /.illustration-holder */}
                </div>
                
              </div>
            </div>
            </div>
    {/* --------------------------------------------------- */}
    <div className="block-wrapper p-4 mt-4">
            <div className="block-style-nine">
              <div className="row align-items-center">
          
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder d-flex justify-content-center">
                    <img src={seniors} alt="illustrator" />
                    {/* <img src="images/assets/ils_07.svg" alt="illustrator" /> */}
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                   
                    <h4 className="text-primary fw-bold pb-2">Seniors  </h4>
               
                     <div className="d-flex justify-content-between"> 
                      <ul >
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Simplified UI    </li>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Transaction Limits  </li>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Spending Alerts   </li>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Bill Payment </li>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Scan & Pay  </li>
                      </ul>
                      <ul>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Transfer   </li>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Add Payee</li>
                        <li className="text-dark"><span><GoDotFill size={15} color="black" /></span> Pending Requests </li>

                      </ul>
                     </div>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            </div>

      </div>
      {/* /.fancy-feature-seven */}



    </div>
  );
};




export default FamilyBanking;
