import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import HeaderPopupForm from "../form/HeaderPopupForm";
// import MegaMenuLanding from "../../components/header/mega-menu/MegaMenuLanding";
import {logoBlack} from "../../views/inner-pages/pages/ImagePath";
import { IoMdArrowDropright } from "react-icons/io";

Modal.setAppElement("#root");

const HeaderLandingFormSurvey = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  const ProductDropdown = [
    {
      img: "home01",
      routerPath: "/retailer-banking",
      inenerText: "",
      inenerTextWrapClass: "",
      clasName: `dropdown-item img-box`,
      title: "Retail Banking",
      subTitle:"Innovative Digital Experiences, Delivered"
    },
    {
      img: "home02",
      routerPath: "/corporate-banking",
      inenerText: "",
      inenerTextWrapClass: "",
      clasName: `dropdown-item img-box`,
      title: "Corporate Banking",
      subTitle:"The Next Frontier in Digital Transformation for Banks"
    },
    {
      img: "home02",
      routerPath: "/digital-onboarding",
      inenerText: "",
      inenerTextWrapClass: "",
      clasName: `dropdown-item img-box`,
      title: "Digital Onboarding",
      subTitle: "Redefine Customer Onboarding Experience",
    },
    {
      img: "home02",
      routerPath: "/family-banking",
      inenerText: "",
      inenerTextWrapClass: "",
      clasName: `dropdown-item img-box`,
      title: "Family Banking",
      subTitle: "Empowering Families with Smart Money Management Tools",
    },
    {
      img: "home02",
      routerPath: "/testing-services",
      inenerText: "",
      inenerTextWrapClass: "",
      clasName: `dropdown-item img-box`,
      title: "Testing Services",
      subTitle: "Manage QA Service",
    },
    {
      img: "home02",
      routerPath: "/vrm-gpt",
      inenerText: "",
      inenerTextWrapClass: "",
      clasName: `dropdown-item img-box`,
      title: "Virtual Relationship Manager (VrmGpt)",
      subTitle: "(VrmGpt)",
    },
    {
      img: "home02",
      routerPath: "/metiz",
      inenerText: "",
      inenerTextWrapClass: "",
      clasName: `dropdown-item img-box`,
      title: "Metiz",
      subTitle: "Zero-Code Low-Code",
    },
  
  ];

  return (
    <>
      {/* =============================================
				Theme Main Menu
			==============================================  */}
      <div
        className={
          navbar
            ? "theme-main-menu sticky-menu  theme-menu-six bg-none fixed"
            : "theme-main-menu sticky-menu  theme-menu-six bg-none"
        }
      >
        <div className="d-flex align-items-center">
          <div className="logo order-lg-1">
            <Link to="/">
              {/* <img src="images/logo/deski_06.svg" alt="brand" /> */}
              <img src={logoBlack} alt="brand" style={{height:'55px', width:'188px'}} />
            </Link>
          </div>


          <div className="right-widget ms-xl-5 order-lg-3">
            <ul className="d-flex align-items-center">
       
              <li>
                <button
                  className="demo-button d-none d-sm-block"
                  onClick={toggleModalOne}
                >
                  <span>Contact</span>
                </button>
              </li>
            </ul>
          </div>

          <nav
            id="mega-menu-holder"
            className="navbar navbar-expand-lg ms-lg-auto order-lg-2"
          >
            <div className="container nav-container">
              <div className="mob-header">
                <button className="toggler-menu" onClick={handleClick}>
                  <div className={click ? "active" : ""}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
              </div>
              {/* End Header */}

              <div
                className="navbar-collapse collapse landing-menu-onepage"
                id="navbarSupportedContent"
              >
                <div className="d-lg-flex justify-content-between align-items-center">
                

                <Scrollspy
                  className="navbar-nav main-side-nav font-gordita"
                  items={["home", "feature", "product", "feedback", "pricing"]}
                  currentClassName="active"
                  offset={-500}
                >
                  <li className="nav-item">
                    <Link to="/" className="nav-link">
                     Home
                    </Link>
                  </li>
              
           


                    <li className="nav-item dropdown" >
                          <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                            Product
                          </a>
                          <ul className="dropdown-menu" style={{width:'380px', overflow:'scroll'}}>
                            {/* --sub menu-------------- */}

                            {/* <li className="dropdown-submenu dropdown">
                              <a
                                href="#"
                                className="dropdown-item dropdown-toggle"
                                data-toggle="dropdown"
                              >
                              Product
                              </a>
                              <ul className="dropdown-menu">
                                {ProductDropdown.map((val, i) => (
                                  <li key={i}>
                                    <Link to={val.routerPath} className="dropdown-item">
                                      {val.title}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </li> */}
                        
                        
                        
                            {/* <li className="mt-0 mb-0 py-0 pb-0" >
                        
                              <ul className="dropdown-item" style={{width:'100%', overflowY:'scroll'}}  >
                                {ProductDropdown.map((val, i) => (
                                  <li key={i} className="pb-2" >
                                    <Link to={val.routerPath} className=" text-primary mb-0 pb-0" >
                                   <b className="pb-0 mb-0"><IoMdArrowDropright />{val.title}</b> 
                                    </Link>
                                    <p className="pt-0 mt-0 fs-6">{val.subTitle}</p>
                                  </li>
                                ))}
                              </ul>
                            </li> */}

                    <li className="mt-0 mb-0 py-0 pb-0" style={{ marginTop: 0, marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}>
                      <ul className="dropdown-item ps-2" style={{ width: '100%', overflowY: 'scroll', padding: 2, margin: 0 }}>
                        {ProductDropdown.map((val, i) => (
                          <li key={i} style={{ paddingBottom: 0, marginBottom: 0, paddingTop: 0, marginTop: 0 }}>
                            <Link to={val.routerPath} className="text-primary mb-0 pb-0" style={{ padding: 0, margin: 0 }}>
                              <b className="pb-0 mb-0" style={{ padding: 0, margin: 0, }}>
                                {val.title}
                              </b>
                            </Link>
                            <p className="pt-0 mt-0 fs-6" style={{ padding: 0, margin: 0 }}>
                              {val.subTitle}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </li>`


                          </ul>
                        </li>


           
                  <li className="nav-item">
                    <Link to="/vr-landing" className="nav-link">
                      Solution Partner
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/about-us" className="nav-link">
                      About Us
                    </Link>
                  </li>
                </Scrollspy>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      {/* /.theme-main-menu */}

      {/* Mobile Menu Start */}
      <div className={click ? "mobile-menu  menu-open" : "mobile-menu"}>
        <div className="logo order-md-1">
          <Link to="/event-organizer">
            <img src="images/logo/deski_06.svg" alt="brand" />
          </Link>
          <div className="fix-icon text-dark" onClick={handleClick}>
            <img src="images/icon/close.svg" alt="icon" />
          </div>
          {/* Mobile Menu close icon */}
        </div>

 
              <Scrollspy
                className="navbar-nav main-side-nav font-gordita"
                items={["home", "feature", "product", "feedback", "pricing"]}
                currentClassName="active"
                offset={-500}
              >
                  <li className="nav-item">
                    <Link to="/" className="nav-link">
                      Home
                    </Link>
                  </li>
          
                  <li className="nav-item ">
                          <a className="nav-link " href="#" >
                            Product
                          </a>
                          {/* <ul className="dropdown-menu">
                           
                            <li>
                        
                              <ul className="dropdown-item">
                                {ProductDropdown.map((val, i) => (
                                  <li key={i}>
                                    <Link to={val.routerPath} >
                                      {val.title}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </li>

                          </ul> */}
                          <ul className="ps-4">
                          <li className="text-dark text-wrap pb-2"> <Link className="dropdown-item" to={ProductDropdown[0].routerPath}> {ProductDropdown[0].title} </Link> </li>
                          <li className="text-dark text-wrap pb-2"> <Link className="dropdown-item" to={ProductDropdown[1].routerPath}> {ProductDropdown[1].title} </Link> </li>
                          <li className="text-dark text-wrap pb-2"> <Link className="dropdown-item" to={ProductDropdown[2].routerPath}> {ProductDropdown[2].title} </Link> </li>
                          <li className="text-dark text-wrap pb-2"> <Link className="dropdown-item" to={ProductDropdown[3].routerPath}> {ProductDropdown[3].title} </Link> </li>
                          <li className="text-dark text-wrap pb-2"> <Link className="dropdown-item" to={ProductDropdown[4].routerPath}> {ProductDropdown[4].title} </Link> </li>
                          <li className="text-dark pb-2"> <Link className="dropdown-item text-wrap" to={ProductDropdown[5].routerPath}> {ProductDropdown[5].title} </Link> </li>
                          <li className="text-dark text-wrap pb-2"> <Link className="dropdown-item" to={ProductDropdown[6].routerPath}> {ProductDropdown[6].title} </Link> </li>
                          </ul>
                        </li>


                  <li className="nav-item">
                    <Link to="/vr-landing" className="nav-link">
                      Solution Partner
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/about-us" className="nav-link">
                      About Us
                    </Link>
                  </li>
             </Scrollspy>
      </div>
      {/* Mobile Menu End */}

      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal  modal-contact-popup-one"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="box_inner ">
          <main className="main-body box_inner modal-content clearfix">
            <button className="close" onClick={toggleModalOne}>
              <img src="images/icon/close.svg" alt="close" />
            </button>
            {/* End close icon */}

            <div className="left-side">
              <div className="d-flex flex-column justify-content-between h-100">
                <div className="row">
                  <div className="col-xl-10 col-lg-8 m-auto">
                    <blockquote>
                      “I never dreamed about success. I worked for it.”
                    </blockquote>
                    <span className="bio">—Estée Lauder</span>
                  </div>
                </div>
                <img
                  src="images/assets/ils_18.svg"
                  alt=""
                  className="illustration mt-auto"
                />
              </div>
            </div>
            {/* /.left-side */}

            <div className="right-side">
              {/* <h2 className="form-title">Contact us</h2> */}
              <HeaderPopupForm />
            </div>
            {/*  /.right-side */}
          </main>

        </div>
      </Modal>
      {/* End  Modal For Request a demo */}
    </>
  );
};

export default HeaderLandingFormSurvey;
