import React from "react";
import { Helmet } from "react-helmet";
import Features from "../../components/form-survey-landing/Features";
import FeaturesAbout from "../../components/form-survey-landing/FeaturesAbout";
import HowWorks from "../../components/form-survey-landing/HowWorks";
import Portfolio from "../../components/form-survey-landing/Portfolio";
import HeroBannerThirteen from "../../components/form-survey-landing/HeroBannerThirteen";
import ServicePortfolio from "../../components/form-survey-landing/ServicePortfolio";
import {homeBg} from "../inner-pages/pages/ImagePath";


const FormSurveyLanding = () => {
  return (
    <div className="main-page-wrapper p0 font-gordita pt-0 pt-0">
      <Helmet>
        <title>
        JBS Global Solutions - Leading Provider of Banking IT Solutions and Digital Transformation Services
        </title>
      </Helmet>


      {/* <HeaderLandingFormSurvey /> */}
      {/* End Header */}

      <HeroBannerThirteen  homeBg={homeBg}/>


        {/* ----------------------------------banner end  ---------------- */}
      <div className="fancy-feature-thirtyFour mt-0 md-mt-0">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12  m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="title-style-eleven text-center mb-70 md-mb-10">
                <div className="upper-title">HOW IT WORKS</div>
                <h4 className="">
                Digitalization, automation, enhanced focus on end user satisfaction and information security across devices and data sources are key drivers that will impact the software testing landscape (NASSCOM report)

                </h4>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <HowWorks />
          </div>
        </div>
      </div>
 {/* ------product sention --------------- */}
      <div
        className="block-style-thirtySix lg-container pt-225 md-pt-120"
        id="feature"
      >
        <div className="container">
          <Features />
        </div>
      </div>
{/* ----------------------------------------------------------- */}
      <div
        className="fancy-feature-thirtyFive position-relative lg-container pt-225 md-pt-120"
        id="product"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="block-style-thirtySix">
                <div className="title-style-fourteen">
                  <div className="upper-title">Product</div>
                   <h2>Lorem Inpsum</h2>
                </div>
                <div className="tag-line">
               <p>  create your won style.</p>
                </div>
              </div>
    
            </div>
          </div>
        </div>
        <div className="slider-wrapper">
          <div className="portfolio_slider_two survey-slider">
            <Portfolio />
          </div>

        </div>

      </div>
{/* ------sulution partner---------------- */}
      <div
        className="fancy-feature-thirtyFive position-relative lg-container pt-225 md-pt-120"
        id="product"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="block-style-thirtySix">
                <div className="title-style-fourteen">
                  <div className="upper-title">Solution Partner</div>
                  <h2>Lorem Inpsum</h2>
                </div>
                <div className="tag-line">
                  Lorem Inpsum
                </div>
              </div>
              {/* <!-- /.block-style-thirtySix --> */}
            </div>
          </div>
        </div>
        <div className="slider-wrapper">
          <div className="portfolio_slider_two survey-slider">
            <ServicePortfolio />
          </div>
          {/* <!-- /.portfolio_slider_two --> */}
        </div>
        {/* <!-- /.slider-wrapper --> */}
      </div>
 
      <div className="block-style-thirtySix lg-container pt-225 md-pt-120">
        <div className="container">
          <FeaturesAbout />
        </div>
      </div>


      {/* <div
        className=" lg-container pt-180 md-pt-110"
        data-aos="fade-up"
        id="feedback"
      >
        <div className="client-feedback-slider-eight">
          <div className="container">
            <div className="main-content position-relative">
              <div className="clientSliderSeven">
                <Testimonial />
              </div>
            </div>
       
          </div>
        </div>
      </div> */}


      {/* <div className="theme-footer-nine lg-container">
        <div className="container">
          <Footer />
        </div>
      </div> */}
    </div>
  );
};

export default FormSurveyLanding;
