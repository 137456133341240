import React from "react";
import { Link } from "react-router-dom";
import Social from "../../../../components/social/Social";
import AboutFour from "../../../../components/about/AboutFour";
import CallToActionThree from "../../../../components/call-to-action/CallToActionThree";
import { GoDotFill } from "react-icons/go";
import {retailer, DevOpsFramework, ManagedQAServices,  Prod4, seamless, identity, kyc, realtime, digitalId, signature, comprehensive, customeAuthentication, sumart_money, Family_Mobile, silent, parent, child, seniors} from "../../pages/ImagePath"
import { MdArrowForward } from "react-icons/md";


const TestingServices = () => {
  return (
    <div className="main-page-wrapper ">
 


      {/* 	=============================================
				Fancy Hero Three
			============================================== */}
      <div className="fancy-hero-three bg-transparent ">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <p className="text-dark fs-6" style={{textAlign:'justify'}}>Managed Test Service of Test Yantra provides value additions which are unique and are un-matchable. Our services will help to reduce the labour cost and reduce the capital investment at the outset. It will eliminate the need to invest on infrastructure and also helps to increase the productivity and accuracy. </p>
                <h3 className="text-primary text-start fw-bold pb-2 pt-5 mt-5"> Testing Services</h3>
                <ol className="text-start">
                  <li className="d-flex">1.<p className="text-dark ps-2">Independent Verification and Validation</p> </li>
                  <li className="d-flex">2. <p className="text-dark ps-2">Project Consulting Services</p> </li>
                  <li className="d-flex">3. <p className="text-dark ps-2">Offshore development centers</p> </li>
                </ol>
          
              </div>
              <div className="container">
                <h6 className="text-center mt-4">1.    Independent Verification and Validation</h6>
                 <div className="pt-2 pb-4">
                  <img src={ManagedQAServices} alt="" />
                </div>
                <p className="text-start text-dark ps-5">
                  2. Project Consulting Services <br />
               <span><MdArrowForward color="blue" />Build, Operate and Transfer </span><br />
               <span><MdArrowForward  color="blue" />Build, Transfer and Fill </span><br />
               <span><MdArrowForward color="blue" />Onsite Execution</span>
               </p>
                <p className="text-start text-dark ps-5 mt-3">
                  3. Offshore Development Centers <br />
               <span><MdArrowForward color="blue" />More than 15000 Sq.Ft office space </span><br />
               <span><MdArrowForward  color="blue" />150+regular employees </span><br />
               <span><MdArrowForward color="blue" />7000 Sq.ft Dedicated Lab</span><br />
               <span><MdArrowForward color="blue" />Readily available space to setup ODC within short notice for 70 and more employees</span><br />
               <span><MdArrowForward color="blue" />secure dedicated lab for Mobile Testing</span>
               </p>
              </div>

           
            </div>
          
          </div>

         
        </div>

         <div className="container">
              <h4 className="text-start text-primary fw-bold mt-4">Devops:</h4>
              <h6 className="text-dark text-start pt-2">Problem Statement:</h6>
              <ol className="text-start mt-3">
                  <li className="d-flex">1.<p className="text-dark ps-2">Traditional software engineering lack seaming co working Correlation between Development, Testing and Operations Teams.</p> </li>
                  <li className="d-flex">2. <p className="text-dark ps-2">Manually deployment of code, or developing code that doesn’t have automated testing, leading to the building up of technical Liability.</p> </li>
                  <li className="d-flex">3. <p className="text-dark ps-2">Lack of coordination between teams leading to unplanned work and same mistakes being repeated, resulting in loss of time, effort and money, and business/revenue too.</p> </li>
              </ol>

              <ul className="text-start mt-5">
              <h6 className="text-dark text-start mt-4 pb-2">Industry Approach and Solution:</h6>

                  <li className="d-flex"><GoDotFill color="black"/> <p className="text-dark ps-2 fs-6" style={{textAlign:'justify'}}>Making continuous testing as a central part of DevOps (Continuous Testing in line with Continuous Development and Continuous Integration)</p> </li>
                  <li className="d-flex"><GoDotFill color="black"/>  <p className="text-dark ps-2" style={{textAlign:'justify'}}>Continuous testing - concept of “fail early and fail often”.</p> </li>
                  <li className="d-flex"><GoDotFill color="black"/>  <p className="text-dark ps-2" style={{textAlign:'justify'}}>Evaluating code quality for predictive analytics & defect-leakage prevention.</p> </li>
                  <li className="d-flex"><GoDotFill color="black"/>  <p className="text-dark ps-2" style={{textAlign:'justify'}}>Maintaining Version control of all production artefacts.</p> </li>
                  <li className="d-flex"><GoDotFill color="black"/>  <p className="text-dark ps-2" style={{textAlign:'justify'}}>Testers automate test case using wireframes (pseudo code).</p> </li>
                  <li className="d-flex"><GoDotFill color="black"/>  <p className="text-dark ps-2" style={{textAlign:'justify'}}>Introducing Continuous integration and deployment - Having the ability to automate in sprint.</p> </li>
                  <li className="d-flex"><GoDotFill color="black"/>  <p className="text-dark ps-2" style={{textAlign:'justify'}}>Automate all pre-testing tasks, test case, test script etc and align with the Continuous Integration cycle.</p> </li>
                  <li className="d-flex"><GoDotFill color="black"/>  <p className="text-dark ps-2" style={{textAlign:'justify'}}>Developing Automation solution from Dev to QA to Acceptance testing.</p> </li>
                  <li className="d-flex"><GoDotFill color="black"/>  <p className="text-dark ps-2" style={{textAlign:'justify'}}>Continuous monitoring of production environment.</p> </li>
              </ul>
           </div>
{/* ---------------------------------------- */}
         <div className="container">
              <h4 className="text-start text-primary fw-bold mt-4">TYSS DevOps Framework</h4>
              <div className="mt-3 mb-4">
                <img src={DevOpsFramework} alt="" />
              </div>
             <div className="mt-3 "> 
             <p className="text-dark ps-2 pb-2" style={{textAlign:'justify'}}>TYSS follows the approach of SHIFT LEFT testing, whose prime focus is on quality and to work on defect prevention than defect detection, by early adaptation of fail fast and fail often with respect to functional defects testing in the software life cycle.</p>
             <p className="text-dark ps-2 pb-2" style={{textAlign:'justify'}}>We create a continuous testing approach that traverse thru the complete CI and CD cycle. </p>
             <p className="text-dark ps-2 pb-2" style={{textAlign:'justify'}}>We take responsibility of automation beyond builds and integrations, to include automated solutions for development (unit testing) and ops (configuration management). </p>
             <p className="text-dark ps-2 pb-2" style={{textAlign:'justify'}}>With this practice we shift the integration testing to a step left in the delivery pipeline in the build process. Which helps us to test error prone integrations early and frequently, in turn help in isolating the significant defects sooner for quick resolution.  </p>
             <p className="text-dark ps-2 pb-2" style={{textAlign:'justify'}}>SWIFT QUALITY – with continuous testing and development, we achieve faster, more accurate results with less problems. Noticeably avoid any kind of rework, any delays and avoid detecting defects in later stages of testing cycle.   </p>
             </div>
           </div>
        {/* /.bg-wrapper  */}
      </div>


    

    </div>
  );
};




export default TestingServices;
