import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Picture18, Picture22, Picture23, Picture24 } from "../../views/inner-pages/pages/ImagePath";

const ServicePortfolio = () => {
  let url =  '/vr-landing';
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };

  const portfolioContent = [
    {
      _id: 1,
      image: Picture18,
      editLink: "#",
      viewLink: url,
      itemName:'Mobeix Digital Banking Platform',
    },
    {
      _id: 2,
      image: Picture22,
      editLink: "#",
      viewLink: url,
      itemName:'Redefine, Quality Engineering',
    },
    {
      _id: 3,
      image: Picture23,
      editLink: "#",
      viewLink: url,
      itemName:'3D AI avatars for Banking solutions',
    },
    {
      _id: 4,
      image: Picture24,
      editLink: "#",
      viewLink: url,
      itemName:'Banking Consulting and Data Engineering',
    },
    {
      _id: 5,
      image: Picture23,
      editLink: "#",
      viewLink: url,
      itemName:'Zero / Low Code Platform',
    },
  ];

  return (
    <>
      <Slider {...settings}>
        {portfolioContent.map((item) => (
          <div className="item" key={item._id}>
            <div className="card-block">
              <img
                src={item.image}
                alt=""
                className="w-100"
                style={{height:'280px', width:'100%', objectFit:'fill'}}
              />
                  <p className="text-center pt-1">{item.itemName}</p>
              <div className="hover tran3s d-flex align-items-center justify-content-center flex-column">
                {/* <a href={item.editLink} className="edit tran3s">
                  Edit
                </a> */}
                <a href={item.viewLink} className="view tran3s">
                  View
                </a>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default ServicePortfolio;
