import React from "react";
import { GoDotFill } from "react-icons/go";
import {aboutBg, retailer, hero_page, Prod1, Prod2, Prod3, Prod4, seamless, identity, kyc, realtime, digitalId, signature, comprehensive, customeAuthentication, sumart_money, Family_Mobile, silent, parent, child, seniors} from "../../pages/ImagePath"



const VrmGpt = () => {
  return (
    <div className=" p0" style={{backgroundColor:'#08111C'}}>
 
   


    


      {/* 	=============================================
				Fancy Feature Seven
			==============================================  */}
      <div className="fancy-feature-seven">
            <div className=" p-4 mt-4">
            <div className="card p-5 ps-0 pe-0"  style={{backgroundColor:'#08111C', height:'200vh'}}>
                <div className="row">
                  <div className="col-4 px-4">
                  <h2 className="text-primary text-start fw-bold pb-1 ms-3" style={{textAlign:'justify'}}>Create Conversational 3D Avatars Effortlessly</h2>
                  </div>
                  <div className="col-8">
                  <img src={hero_page} alt="illustrator"  style={{height:'400px', width:'100%', objectFit:'contain'}}/>
                  </div>
                </div>
        


        
            </div>
            </div>
      </div>
      {/* /.fancy-feature-seven */}



    </div>
  );
};




export default VrmGpt;
