
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AllRoutes from "./router/AllRoutes";

const App = () => {
  return (
    <Router>
      <AllRoutes />
    </Router>
  );
};

export default App;







// import React, { useEffect } from "react";
// import { BrowserRouter as Router } from "react-router-dom";
// import { Helmet } from "react-helmet";
// import AllRoutes from "./router/AllRoutes";
// import ScrollToTop from "./components/ScrollToTop";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import "photoswipe/dist/photoswipe.css";
// import "bootstrap/dist/js/bootstrap";

// const App = () => {
//   useEffect(() => {
//     AOS.init({
//       duration: 1200,
//     });
//   }, []);

//   window.addEventListener("load", AOS.refresh);

//   return (
//     <Router>
//       <Helmet>
//         <title>Deski - Saas & Software React Template</title>
//         <meta property="og:site_name" content="deski" />
//         <meta
//           property="og:url"
//           content="https://themeforest.net/user/ib-themes"
//         />
//         <meta property="og:type" content="website" />
//         <meta
//           property="og:title"
//           content="Deski: Creative Saas & Software React Template"
//         />
//         <meta
//           name="keywords"
//           content="agency, bootstrap 5, business, clean, corporate, creative, fullpage, minimal, modern, multipurpose, parallax, personal, photography, portfolio, showcase, Digital marketing agency, Digital marketing company, Digital marketing services, sass, software company"
//         />
//         <meta
//           name="description"
//           content="Deski is a creative saas and software React template designed for saas and software Agency websites."
//         />
//         <meta name="description" content="Portfolio & Agency React Template" />
//       </Helmet>

//       {/* Single instance of ScrollToTop and Routes */}
//       <ScrollToTop />
//       <AllRoutes />
//     </Router>
//   );
// };

// export default App;





// import React, { useEffect } from "react";
// import { Helmet } from "react-helmet";
// import AllRoutes from "./router/AllRoutes";
// import ScrollToTop from "./components/ScrollToTop";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import "photoswipe/dist/photoswipe.css";
// import "bootstrap/dist/js/bootstrap";


// const App = () => {
//   useEffect(() => {
//     AOS.init({
//       duration: 1200,
//     });
//   }, []);
//   window.addEventListener("load", AOS.refresh);

//   return (
//     <>
//       <Helmet>
//         <title>Deski - Saas & Software React Template</title>
//         <meta property="og:site_name" content="deski" />
//         <meta
//           property="og:url"
//           content="https://themeforest.net/user/ib-themes"
//         />
//         <meta property="og:type" content="website" />
//         <meta
//           property="og:title"
//           content="Deski: Creative Saas & Software React Template"
//         />
//         <meta
//           name="keywords"
//           content="agency, bootstrap 5, business, clean, corporate, creative, fullpage, minimal, modern, multipurpose, parallax, personal, photography, portfolio, showcase, Digital marketing agency, Digital marketing company, Digital marketing services, sass, software company"
//         />
//         <meta
//           name="description"
//           content="Deski is a creative saas and software React template designed for saas and software Agency websites."
//         />
//         <meta name="description" content="Portfolio & Agency React Template" />
//       </Helmet>
//       {/* End Seo Helmt */}

//       <ScrollToTop />
//       {/* End Scroll top */}

//       <AllRoutes />

//     </>
//   );
// };

// export default App;
