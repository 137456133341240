import React from "react";
import { Link } from "react-router-dom";
import Social from "../../../../components/social/Social";
import AboutFour from "../../../../components/about/AboutFour";
import CallToActionThree from "../../../../components/call-to-action/CallToActionThree";
import { GoDotFill } from "react-icons/go";
import {aboutBg, retailer, Prod1, Prod2, Prod3, Prod4, seamless, identity, kyc, realtime, digitalId, signature, comprehensive, customeAuthentication, sumart_money, Family_Mobile, silent, parent, child, seniors} from "../../pages/ImagePath"



const DigitalOnboarding = () => {
  return (
    <div className="main-page-wrapper p0">
 
 <div className="container-fluid mt-150 ps-0 pe-0">
      <div   style={{height:'500px', width:'100%', position:'relative', zIndex:2}}>
         <img src={retailer} alt=""  style={{width:'100%', height:'350px'}}  />
         <div className="position-absolute" style={{marginTop:'4%', marginLeft:'4%', zIndex:5}}>
         <h2 className="ps-0 fsm-bold  text-danger text-start" >MOBEIX™ <br />
          DIGITAL ONBOARDING</h2>
         <h4 className="text-primary ">Redefine Customer Onboarding Experience</h4>
         </div>

      </div>

     
     </div>

      {/* 	=============================================
				Fancy Hero Three
			============================================== */}
      <div className="fancy-hero-three bg-transparent">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h3 className="text-dark fw-bold pb-2" >Deliver Next-Generation Banking Solutions, Today</h3>
                <p className="text-dark text-justify" style={{textAlign:'justify'}}>
                The increased use of digital capabilities and channels across all industries has had and continues to have a dramatic impact on how customers expect to engage with their bank. Knowing that you only have one chance to make a first impression puts great pressure on providing a customer-friendly, frictionless experience for how a customer becomes your customer. The onboarding journey is the customer’s first impression of how they will be able to interact you with the bank once an account is opened. Additionally, ensuring a smooth onboarding process can give the banks an edge over competitors as well as enhancing how existing customer relationships are expanded with new products and services.
                </p>
          
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>
      {/* /.fancy-hero-three */}

      {/* 	=============================================
				Fancy Feature Seven
			==============================================  */}
      <div className="fancy-feature-seven">
        <div className="container">
          <div className="title-style-two text-center mb-30 md-mb-70">
     
            <h3 className="fw-bold pb-0 mb-0">
            Streamline the Customer Onboarding Journey
         
             
            </h3>
          </div>
          {/* /.title-style-two */}

          <div className="block-wrapper">
            <div className="block-style-nine">
              <div className="row align-items-center">
                
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <p className="font-rubik pb-2" style={{textAlign:'justify'}}>
                    Mobeix™ Digital Onboarding, enables banks to accelerate the customer onboarding journey by automating the end-to-end process in a simplified and streamlined manner by using the most current biometrics capabilities, capturing accurate information, adhering to regulatory standards and optimising operational efficiency.
                    </p>
                    <p className="font-rubik pb-2" style={{textAlign:'justify'}}>
                    Our solution can provide the bank with the ability to provide a seamless onboarding experience, end to end, without any human intervention, or provide human intervention when and where required
                    </p>
         
                  </div>
                  {/* /.text-wrapper */}
                </div>
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src={Prod4} alt="illustrator" style={{ height:'80%',width:'80%'}} />
                    {/* <img src="images/assets/ils_04.svg" alt="illustrator" /> */}
                  </div>
                  {/* /.illustration-holder */}
                </div>
              </div>
            </div>
   


            {/* /.block-style-nine */}
            <div className="block-style-nine mt-5">
              <div className="row align-items-center">
              <h4 className="text-primary fw-bold">Mobeix™ Digital Onboarding Capabilities</h4>
              <p>Our solution automates the complete onboarding process, providing personalised customer experience.</p>
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder d-flex justify-content-center">
                    <img src={seamless} alt="illustrator"  />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h4 className="text-primary fw-bold">Seamless Data Capture & Extraction</h4>
           
                    <p className="font-rubik" style={{textAlign:'justify'}}>
                    Collect customer’s data either through manual entry or retrieving information from identity documents using OCR (Optical Character Recognition) technology.
                    </p>
           
                    <p className="font-rubik" style={{textAlign:'justify'}}>
                    Auto-filling of data from the scanned documents.
                    </p>
               
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder d-flex justify-content-center">
                    <img src={identity} alt="illustrator" />
                    {/* <img src="images/assets/ils_07.svg" alt="illustrator" /> */}
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h4 className="text-primary fw-bold">Identity Proof</h4>
               
                    <p className="font-rubik" style={{textAlign:'justify'}}>
                    The solution validates the scanned identity documents including the images from mobile or tablet.
                    </p>
                    <p className="font-rubik" style={{textAlign:'justify'}}>
                    Validates the proof with highly secured features such as hologram, edge detection etc. by use of advanced technologies such as AI and ML.
                    </p>
              
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
          </div>
        </div>
      </div>
      {/* /.fancy-feature-seven */}

      {/* =====================================================
				Fancy Text Block Thirteen
			===================================================== */}
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-two">
                  <h4 className="fw-bold text-primary">eKYC AML/Blacklist Checks</h4>
                </div>
                <p className="text-dark" style={{textAlign:'justify'}}> Verification of captured customer’s data for blacklist or AML by integrating with KYC (Know Your Customers) for identity checks.</p>
                <p className="text-dark" style={{textAlign:'justify'}}> Validation of background check helps to expose the customer’s credibility and for credit scoring.</p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <img src={kyc} alt="feature"  />
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

{/* ------------------------------------------- */}
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
          <div
              className="col-lg-6 col-md-8 m-auto order-lg-last  d-flex justify-content-center align-item-center"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <img src={realtime} alt="feature"   />
            </div>
            <div
              className="col-lg-6 order-lg-first"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-two">
                  <h4 className="fw-bold text-primary">Real-time Proof of Ownership</h4>
                </div>
                <p  className="text-dark" style={{textAlign:'justify'}}> Confirmation of proof of possession of document by asking the customer to take a selfie and validating the same with picture captured from the document including AI features to support liveness detection and other facial features. </p>
                <p  className="text-dark" style={{textAlign:'justify'}}> User validation can be supplemented through Email/SMS OTP. </p>
              </div>
            </div>
            {/* End .col */}
       
     
          </div>
        </div>
      </div>



      {/* ------------------- */}
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-two">
                  <h4 className="fw-bold text-primary">Digital ID</h4>
                </div>
                <p  className="text-dark" style={{textAlign:'justify'}}> E-signature/Digital Signature feature enables banks to provide a digital seal on the onboarding digital document generated for the customer. </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <img src={digitalId} alt="feature" />
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      {/* --------------------- */}
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6   order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-two">
                  <h4 className="fw-bold text-primary">Digital Signature</h4>
                </div>
                <p  className="text-dark" style={{textAlign:'justify'}}>E-signature/Digital Signature feature enables banks to provide a digital seal on the onboarding digital document generated for the customer.</p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-last d-flex justify-content-center"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <img src={signature} alt="feature" />
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
      {/* --------------------- */}
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6   order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-two">
                  <h4 className="fw-bold text-primary">Comprehensive onboarding flow</h4>
                </div>
                <p  className="text-dark" style={{textAlign:'justify'}}>Comprehensive onboarding flow covering product suggestion, creation of customer user id and password across all channels,</p>
                <p  className="text-dark" style={{textAlign:'justify'}}>Setting up an account in the core system and integration to any payment system to fulfil funding needs.</p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto  order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <img src={comprehensive} alt="feature" />
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
      {/* --------------------- */}
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6   order-lg-first"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-two">
                  <h4 className="fw-bold text-primary">Customer Authentication</h4>
                </div>
                <p  className="text-dark" style={{textAlign:'justify'}}>Customer is authenticated through two-factor authentication mechanism such as Email/SMS OTP as part of the onboarding process.</p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-last d-flex justify-content-center"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <img src={customeAuthentication} alt="feature" />
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
      {/* /.fancy-text-block-thirteen */}

    </div>
  );
};




export default DigitalOnboarding;
