import React from "react";
import { Link } from "react-router-dom";
import Social from "../../../../components/social/Social";
import AboutFour from "../../../../components/about/AboutFour";
import CallToActionThree from "../../../../components/call-to-action/CallToActionThree";
import { GoDotFill } from "react-icons/go";
import {aboutBg, retailer, Prod1, Prod2, Prod3, Prod4, seamless, identity, kyc, realtime, digitalId, signature, comprehensive, customeAuthentication, sumart_money, Family_Mobile, silent, parent, child, seniors} from "../../pages/ImagePath"



const CorporateBanking = () => {
  return (
    <div className="main-page-wrapper p0">
 
 <div className="container-fluid mt-150 ps-0 pe-0">
      <div   style={{height:'500px', width:'100%', position:'relative', zIndex:2}}>
         <img src={retailer} alt=""  style={{width:'100%', height:'350px'}}  />
         <div className="position-absolute" style={{marginTop:'4%', marginLeft:'4%', zIndex:5}}>
         <h2 className="ps-0 fsm-bold  text-danger text-center" >MOBEIX CORPORATE BANKING</h2>
         <h4 className="text-primary ">The Next Frontier in Digital Transformation for Banks</h4>
         </div>

      </div>

     
     </div>

      {/* 	=============================================
				Fancy Hero Three
			============================================== */}
      <div className="fancy-hero-three bg-transparent">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h3 className="text-dark fw-bold pb-2" >Deliver Next-Generation Banking Solutions, Today</h3>
                <p className="text-dark text-justify" style={{textAlign:'justify'}}>
                Banks have been slow to digitise corporate banking, as products are complex and it has been driven as a bank-led engagement through relationship managers rather than a self-driven engagement by the clients. Now, though, the acceleration of customers usage of digital channels is forcing banks to adopt digital innovation to transform corporate banking to deliver real-time account and transaction information to relevant stakeholders in the business enabling them to make informed decisions.
                </p>
          
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>
      {/* /.fancy-hero-three */}

      {/* 	=============================================
				Fancy Feature Seven
			==============================================  */}
      <div className="fancy-feature-seven">
        <div className="container">
          <div className="title-style-two text-center mb-150 md-mb-70">
     
            <h3 className="fw-bold pb-0 mb-0">
            Digitise Complex Corporate Banking Functions with Ease
              <span>
            
                <img src="images/shape/line-shape-8.svg" alt="line shape" />
              </span>{" "}
             
            </h3>
          </div>
          {/* /.title-style-two */}

          <div className="block-wrapper">
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src={Prod4} alt="illustrator" style={{objectFit:'fill', height:'80%',width:'80%'}} />
                    {/* <img src="images/assets/ils_04.svg" alt="illustrator" /> */}
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <p className="font-rubik pb-2" style={{textAlign:'justify'}}>
                    Mobeix Corporate Banking enables banks to manage and optimize their corporate client engagement across any device and channel. Clients have access to a comprehensive dashboard detailing their liquidity position across accounts, upcoming payments and receipts to make better informed cash management decisions. Clients can execute all their transactions through a single interface and receive real-time notifications for various banking transactions. Advanced workflows, access control rights and corporate admin facilities allow business to effectively manage their users.
                    </p>
         
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                {/* <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/ils_05.svg" alt="illustrator" />
                  </div>{" "}
             
                </div> */}
                <div
                  className="col-lg-12 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    
                    <h4 className="fw-bold text-primary" style={{textAlign:'justify'}}>
                    Mobeix Corporate Banking Features
                    </h4>
                      <div className="row mt-4">
                        <div className="d-flex justify-content-center align-items-center p-4">
                            <img src={aboutBg} alt=" " style={{objectFit:'fill', height:'250px', width:'80%'}} />
                        </div>
                        <p className="pt-2" style={{textAlign:'justify'}}> 
                        Tagit’s omnichannel (internet and mobile) out-of-the-box digital corporate banking product provides business/corporate banking features that can be deployed quickly with real-time integration to the bank’s back end and host systems
                        </p>
                      </div>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}


            {/* /.block-style-nine */}
            <div className="block-style-nine mt-5">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src={Prod4} alt="illustrator"  style={{width:'70%', height:'70%'}}/>
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h4 className="text-primary fw-bold">Dashboard Services</h4>
                  
                    <p className="font-rubik" style={{textAlign:'justify'}}>
                    The solution provides a comprehensive dashboard with pictorial representation of multiple company account balances in all the different currencies, accounts transaction, forex rates and notifications. It also provides the real-time transaction and settlement status and information on the go.
                    </p>
               
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder d-flex justify-content-center">
                    <img src={Prod4} alt="illustrator" />
                    {/* <img src="images/assets/ils_07.svg" alt="illustrator" /> */}
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h4 className="text-primary fw-bold">Account Services</h4>
               
                    <p className="font-rubik" style={{textAlign:'justify'}}>
                    The solution enables the SMEs to know the real-time balances and account information for all the different types of accounts in a single view. Provides aggregation of balances by currency and for each subsidiary within organisation group. SMEs are empowered with the account details, transaction history for the individual accounts.
                    </p>
              
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
          </div>
        </div>
      </div>
      {/* /.fancy-feature-seven */}

      {/* =====================================================
				Fancy Text Block Thirteen
			===================================================== */}
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-two">
                  <h4 className="fw-bold text-primary">Cash and Liquidity Services</h4>
                </div>
                <p className="text-dark" style={{textAlign:'justify'}}> The solution provides a range of cash management services to SMEs starting from simple transfers to complex functions like batch uploads, payroll, sweep services etc. </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <img src={Prod2} alt="feature"  style={{width:'70%', height:'70%'}}/>
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

{/* ------------------------------------------- */}
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
          <div
              className="col-lg-6 col-md-8 m-auto order-lg-last "
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <img src={Prod3} alt="feature" style={{width:'70%', height:'70%'}} />
            </div>
            <div
              className="col-lg-6 order-lg-first"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-two">
                  <h4 className="fw-bold text-primary">Trade Finance Services</h4>
                </div>
                <p  className="text-dark" style={{textAlign:'justify'}}> The solution provides a range of trade finance services that can be created online without manual intervention. Complex trade/documentary credit applications are carefully designed to provide simplified and assisted user journey, that would help the corporates to accelerate the end-to-end process. SMEs would be able to create LC, BG, Shipping Guarantee, Bill collections online. </p>
              </div>
            </div>
            {/* End .col */}
       
     
          </div>
        </div>
      </div>



      {/* ------------------- */}
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-two">
                  <h4 className="fw-bold text-primary">Corporate Lending/Credit Lines</h4>
                </div>
                <p  className="text-dark" style={{textAlign:'justify'}}> The solution provides a single view of all the different credit lines of the SMEs. The information would help the SMEs to know the outstanding principles, limits consumed and available limit under each credit lines. Contracts are grouped logically into different products that would allow the SMEs to get the clear representation of the outstanding liabilities. </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <img src={Prod2} alt="feature" />
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      {/* --------------------- */}
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6   order-lg-first"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-two">
                  <h4 className="fw-bold text-primary">Scalable and Collaborative Platform</h4>
                </div>
                <p  className="text-dark" style={{textAlign:'justify'}}>Relationships between corporate banks and their clients are traditionally managed by relationship managers (RMs). Our corporate banking solution allows RMs to spend more time with their clients with greater automation thereby reducing back-office administration requirements.</p>
                <p  className="text-dark" style={{textAlign:'justify'}}>The Mobeix Digital Banking platform allows seamless interfacing and integration with other fintech and third-party vendors to deliver a combined value proposition for the bank’s corporate customers.</p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-last"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <img src={Prod2} alt="feature" />
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
      {/* /.fancy-text-block-thirteen */}

    </div>
  );
};


export default CorporateBanking;
