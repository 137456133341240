import React, {useState} from "react";
import { Link } from "react-router-dom";
import HeaderPopupForm from "../form/HeaderPopupForm";
import Modal from "react-modal";
const HeroBannerThirteen = ({homeBg}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);


  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  return (
    <>

    <div className="hero-banner-thirteen lg-container" id="home">
      <div className="position-relative ps-0 pe-0">
      <img src={homeBg}  alt="home bg" style={{objectFit:'cover', height:'650px', width:'100%'}}/> 
      </div> 
      <div className="container position-absolution" style={{marginTop:'-40%'}}>
        <div className="row">
          <div className="col-lg-12">
             <h4 className="text-white pt-0 pb-0">Innovate, Integrate, Quality and Efficiency </h4>
            <h5 className="hero-heading text-white">Leading the  future of Banking Solution</h5>
            <p className="hero-sub-heading text-white">
            At JBS Global Solutions, we empower businesses with cutting-edge strategies and solutions to thrive in a competitive landscape. JBS Global Solutions is in the business of Banking Product, AI Based Solutions, ODC, Business Consulting, and Resourcing.
            </p>
            <button  className="btn btn-light px-3"        onClick={toggleModalOne}>
                   <span className="text-black">Get Started</span>
           </button>
        
          </div>
        </div>

        <div className="screen-holder">
          {/* <div className="position-relative">
          <img
            src="images/assets/ils_22.svg"
            alt="illustration"
            className="img-meta"
          />
             <div className="position-absolute" style={{marginTop:'-40%', marginLeft:'17%'}}>
           <a href="#" className="theme-btn-fourteen"        onClick={toggleModalOne}>
                   Get Started
           </a>
          </div>
          </div> */}
       
            
          {/* <img
            src="images/assets/ils_22.1.svg"
            alt="illustration"
            className="shapes shape-one"
          />
          <img
            src="images/assets/ils_22.2.svg"
            alt="illustration"
            className="shapes shape-two"
          /> */}
        </div>
        {/* <!-- /.screen-holder --> */}
      </div>


    </div>


          
    <Modal
    isOpen={isOpen}
    onRequestClose={toggleModalOne}
    contentLabel="My dialog"
    className="custom-modal  modal-contact-popup-one"
    overlayClassName="custom-overlay"
    closeTimeoutMS={500}
  >
    <div className="box_inner ">
      <main className="main-body box_inner modal-content clearfix">
        <button className="close" onClick={toggleModalOne}>
          <img src="images/icon/close.svg" alt="close" />
        </button>
    
        <div className="left-side">
          <div className="d-flex flex-column justify-content-between h-100">
            <div className="row">
              <div className="col-xl-10 col-lg-8 m-auto">
                <blockquote>
                  “I never dreamed about success. I worked for it.”
                </blockquote>
                <span className="bio">—Estée Lauder</span>
              </div>
            </div>
            <img
              src="images/assets/ils_18.svg"
              alt=""
              className="illustration mt-auto"
            />
          </div>
        </div>


        <div className="right-side">
      
          <HeaderPopupForm />
        </div>
  
      </main>

    </div>
  </Modal>
  </>
  );
};

export default HeroBannerThirteen;
