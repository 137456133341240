import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Prod1, Prod2, Prod3, Prod4 } from "../../views/inner-pages/pages/ImagePath"




const Portfolio = () => {
  let url =  '/product-landing';

  
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };

  const portfolioContent = [
    {
      _id: 1,
      image: Prod1,
      editLink: "#",
      viewLink: url,
      itemName:'Mobile Retail Banking',
    },
    {
      _id: 2,
      image: Prod2,
      editLink: "#",
      viewLink: url,
      itemName:'Mobix Corporate Banking',
    },
    {
      _id: 3,
      image: Prod3,
      editLink: "#",
      viewLink: url,
      itemName:'Mobix Family Banking',
    },
    {
      _id: 4,
      image:Prod4,
      editLink: "#",
      viewLink: url,
      itemName:'Mobix Digital Onboarding',
    },
  ];

  return (
    <>
      <Slider {...settings}>
        {portfolioContent.map((item) => (
          <div className="item" key={item._id}>
            <div className="card-block">
            <img
                src={item?.image}
                // src={`images/media/${item.image}.jpg`}
                alt=""
                className="w-100"
                style={{height:'280px', width:'100%', objectFit:'fill'}}
              />
              <p className="text-center pt-1">{item.itemName}</p>
              <div className="hover tran3s d-flex align-items-center justify-content-center flex-column">
                {/* <a href={item.editLink} className="edit tran3s">
                  Edit
                </a> */}
                <a href={item.viewLink} className="view tran3s">
                  View
                </a>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default Portfolio;
