import React from "react";
import { Link } from "react-router-dom";
import FormFooterSignup from "../form/FormFooterSignup";
import { logoBlack } from "../../views/inner-pages/pages/ImagePath";

const FooterSeven = () => {
  return (
    <div className="row ">
      <div
        className="col-xl-3 col-lg-2 mb-40"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="logo">
          <a href="/">
            <img src={logoBlack} alt="logo" style={{height:'47px', width:'137px'}} />
          </a>
        </div>
      </div>
      {/* End .col */}

      <div
        className="col-lg-9 col-md-9 mb-40 "
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <h5 className="title">Links</h5>
        <ul className="footer-list d-flex justify-content-between">
          <li>
            <Link to="/">Home</Link>
          </li>
   
          <li>
            <Link to="/about-us">About us</Link>
          </li>
          <li>
            <Link to="/product-landing">Product</Link>
          </li>
          <li>
            <Link to="/vr-landing">Solution Partner</Link>
          </li>
       
        </ul>
      </div>

{/* 
      <div
        className="col-xl-4 col-lg-5 mb-40"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="200"
      >
        <div className="newsletter">
          <h5 className="title">Newslettert</h5>
          <p>
            Join over <span>68,000</span> people getting our emails
          </p>

          <FormFooterSignup />

          <div className="info">
            We only send interesting and relevant emails.
          </div>
        </div>
      
      </div> */}
    </div>
  );
};

export default FooterSeven;
