import React from "react";
import { Link } from "react-router-dom";
import Social from "../../../../components/social/Social";
import AboutFour from "../../../../components/about/AboutFour";
import CallToActionThree from "../../../../components/call-to-action/CallToActionThree";
import { GoDotFill } from "react-icons/go";
import {aboutBg, retailer, metiz_logos, Centelon_Logo} from "../../pages/ImagePath"



const Metiz = () => {
  return (
    <div className="main-page-wrapper p0">



      {/* 	=============================================
				Fancy Feature Seven
			==============================================  */}
      <div className="fancy-feature-seven">
   
  
        <div className="container">



     
            {/* /.block-style-nine */}
            <div className="block-wrapper p-4 mt-4">
            <div className="bg-white p-4" style={{height:'60vh'}}>
 
              <div className="row align-items-center">
          
                <div
                  className="col-lg-5 col-12 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder d-flex justify-content-center  align-item-center card" style={{height:'40vh'}}>
                    <div className=" p-3  d-flex justify-content-center  align-item-center">
                    <img src={ metiz_logos} alt="illustrator" />
                    </div>
  
                  </div>
         
                </div>
                <div
                  className="col-lg-7 col-12 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                   
                    <h4 className="text-primary fw-bold pb-2">About Metiz </h4>
               
                     <div className="d-flex justify-content-between"> 
                      <p className="text-dark pb-2 pt-2" style={{textAlign:'justify'}}>A zero-code platform to help your users design their business processes at a lightning-fast pace. Optimize workflows and improve outcomes with AI-empowered automation that simplifies document processes and repetitive tasks with a few clicks. Improve your customer and employee experience by orchestrating collaborative workflows across applications.</p>
                     </div>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            </div>

            {/* ----------------------------------child */}
            <div className="block-wrapper p-4 mt-4">
            <div className="bg-white p-4" style={{height:'60vh'}}>

              <div className="row align-items-center">
              <div
                  className="col-lg-7 order-lg-last"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
             <div className="text-wrapper">
                   
                   <h4 className="text-primary fw-bold pb-2">About Centelon </h4>
              
                    <div className="d-flex justify-content-between"> 
                     <p className="text-dark pb-2 pt-2" style={{textAlign:'justify'}}>
                     Centelon Solutions is a business-technology solutions and services company enabling organizations to innovate using digital and data technologies. We are a trusted partner of large and mid-size companies in the Banking, Insurance, Education, Logistics, Energy & Utility domains. We have helped some of the well-known businesses across the globe by streamlining their processes and addressing their major pain points. Our offices are in Australia, India, New Zealand, and Singapore.</p>
                    </div>
                 </div>{" "}
              
                </div>
                <div
                  className="col-lg-5 col-md-9 m-auto order-lg-first card "
                  data-aos="fade-left"
                  data-aos-duration="1200"
                  
                >
                  <div className="illustration-holder d-flex justify-content-center align-item-center " style={{height:'40vh'}}>
            
                    <div className=" p-3  d-flex justify-content-center  align-item-center" >
                    <img src={Centelon_Logo} alt="illustrator" />
                    </div>
                  </div>
                  {/* /.illustration-holder */}
                </div>
                
              </div>
            </div>
            </div>
            </div>

      </div>
      {/* /.fancy-feature-seven */}



    </div>
  );
};




export default Metiz;
