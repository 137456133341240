import React from "react";
import { Link } from "react-router-dom";
// import { AiFillProduct } from "react-icons/ai";
import {Picture18, Picture22, Picture23, Picture24 } from "../../views/inner-pages/pages/ImagePath";
import { FaCircleDot } from "react-icons/fa6";

const SolutionFancyFeature = () => {
  return (
    <>
      <div className="block-style-sixteen" style={{ background: "#EBF3F1" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: "#DEEAE7" }}
              >
                <img src="images/icon/73.svg" alt="icon" />
          
              </div>
              <h3 className="title">
              Mobeix Digital Banking Platform
                  </h3>
              <p>
              Tagit offers an open API platform, and prepackaged products:. Retail and Corporate Banking, Digital Onboarding and Family Banking.  Built on the <span className="text-primary"> Mobeix Platform </span> these products are micro-services based and cloud native offering clients highly scalable and secure applications for all their channels.
              </p>
              <Link to="#">
                <img src="images/icon/72.svg" alt="icon" />
              </Link>
            </div>
          </div>
        </div>
 
        <img
          src={Picture18}
          // src="images/assets/screen_02.png"
          alt=""
          className="shapes screen-one p-lg-5 p-2"
          style={{ backgroundColor: "#E1E7ED" }}
        />
  

      </div>
      {/* /.block-style-sixteen */}

      <div className="block-style-sixteen" style={{ background: "#EDF3F9" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6 ms-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center  p-4"
                style={{ backgroundColor: "#E1E7ED" }}
              >
                <div >

                <img src="images/icon/74.svg" alt="icon"   />
                </div>
              </div>
              <h3 className="title">Redefine, Quality Engineering.</h3>
              <p>
              Test Yantra’s as Integrated services. Offer end to end Testing for Banking and financial services.
              </p>
              <p><FaCircleDot color="#34ebe1" size={15} /> <span>TaaSa  </span> <br />
                 <span className="ps-3"><FaCircleDot color="#000000" size={15} />  Testing Centre of Excellence, Advisory and Consult,</span><br />
                 <span className="ps-3"><FaCircleDot color="#000000" size={15} /> Automation as a Core, Testing Competency Centre                 </span><br />
                 <span className="ps-3"><FaCircleDot color="#000000" size={15} />  4000+ Employees, 412+ Customers, 4 GDC                 </span><br />
                 </p>
              <Link to="#">
                <img src="images/icon/72.svg" alt="icon" />
              </Link>
            </div>
          </div>
        </div>
        
        <img
          src={Picture22}
          // src="images/assets/screen_03.png"
          alt="screen"
          className="shapes screen-two p-lg-5 p-2"
          style={{ backgroundColor: "#E1E7ED" }}
        />
       

      </div>
      {/* /.block-style-sixteen */}

      <div className="block-style-sixteen" style={{ background: "#F9F1EF" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: "#EDE5E3" }}
              >
                <img src="images/icon/75.svg" alt="icon" />
              </div>
              {/* <h3 className="title">3D AI avatars for Banking solutions</h3> */}
              <p>
              MetaBrix Labs, a pioneer in 3D AI technology, brings its cutting-edge solutions to the banking sector, enabling institutions to transform customer support and engagement. With MetaBrix’s personalized 3D AI avatars, banks can provide immersive and intelligent virtual assistance, enhancing operational efficiency and customer satisfaction.
              </p>
              <Link to="#">
                <img src="images/icon/72.svg" alt="icon" />
              </Link>
            </div>
          </div>
        </div>
        <img
        //   src="images/assets/screen_04.png"
          src={Picture23}
          alt="screen"
          className="shapes screen-three p-lg-5 p-2"
          style={{ backgroundColor: "#E1E7ED" }}
        />
          
      </div>
      {/*  /.block-style-sixteen */}

      <div className="block-style-sixteen" style={{ background: "#cce7f0" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6 ms-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: "#E1E7ED" }}
              >
                <img src="images/icon/74.svg" alt="icon" />
              </div>
              <h3 className="title">Banking Consulting and Data Engineering</h3>
              <p>
              Centelon Solutions is a trusted business-technology solutions and services partner for Banking industry, Our full suite of solutions are designed to bridge the gap from strategy thru implementation and maintenance
              </p>
          
              <Link to="#">
                <img src="images/icon/72.svg" alt="icon" />
              </Link>
            </div>
          </div>
        </div>
        <img
          src={Picture22}
          // src="images/assets/screen_03.png"
          alt="screen"
          className="shapes screen-two p-lg-5 p-2"
          style={{ backgroundColor: "#E1E7ED" }}
        />
      </div>
      {/*  */}
      <div className="block-style-sixteen" style={{ background: "#b5bfb2" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6 ms-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: "#E1E7ED" }}
              >
                <img src="images/icon/74.svg" alt="icon" />
              </div>
              <h3 className="title">Zero / Low Code Platform</h3>
              <p>
              Our <span className="text-primary"> CONSULTING practice’s </span>proprietary reference frameworks & capability models power up our customers’ transformation
              </p>
              <p ><FaCircleDot color="#000000" size={15} /> 
                  The <span className="text-primary"> DATA ENGINEERING  </span> practice delivers services that create impact across end-to-end data lifecycle
                 </p>
              <Link to="#">
                <img src="images/icon/72.svg" alt="icon" />
              </Link>
            </div>
          </div>
        </div>
        <img
          src={Picture23}
          // src="images/assets/screen_03.png"
          alt="screen"
          className="shapes screen-two p-lg-5 p-2"
          style={{ backgroundColor: "#E1E7ED" }}
        />
      </div>
    </>
  );
};

export default SolutionFancyFeature;
