import React from "react";
import { Outlet } from "react-router-dom";
import HeaderLandingFormSurvey from "./components/form-survey-landing/HeaderLandingFormSurvey";
import FooterSeven from "./components/footer/FooterSeven";


const MainLayout = () => {



  return (
    <>
      <div className="main-page-wrapper p0 font-gordita">
  
      <HeaderLandingFormSurvey />

      <div className="main-content">
        <Outlet />
        
      </div>


     <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <FooterSeven />
          </div>

        </div>
   
      </footer>
      </div>
    </>
  );
};

export default MainLayout;
