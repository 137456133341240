import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../../../components/header/HeaderFour";
import FancyVideoOne from "../../../../components/video/FancyVideoOne";
import AboutTabs from "../../../../components/block-slider/AboutTabs";
import CounterThree from "../../../../components/counter/CounterThree";
import TeamFive from "../../../../components/team/TeamFive";
import Faq from "../../../../components/faq/Faq";
import CallToActionFive from "../../../../components/call-to-action/CallToActionFive";
import FooterFour from "../../../../components/footer/FooterFour";
import BlogStyle5 from "../../../../components/blog/BlogStyle5";
import {ceo, Picture3,  aboutBg, Picture4,Picture5, Picture6, Picture7,Picture8, Picture9, Picture10, Picture11, Picture12,  Picture13, Picture14, Picture15, Picture16, Picture17 } from "../../pages/ImagePath"
import { GoDotFill } from "react-icons/go";
import FaqClassicThree from "../../../../components/faq/FaqClassicThree";

const EventOrganizer = () => {

  const imageNames = [
    { imgUrl:Picture3}, { imgUrl:Picture4},{ imgUrl: Picture5}, { imgUrl:Picture6},{ imgUrl: Picture7}, { imgUrl:Picture8},
     { imgUrl:Picture9}, { imgUrl:Picture10},{ imgUrl: Picture11}, { imgUrl:Picture12}, { imgUrl:Picture13}, { imgUrl:Picture14},
     { imgUrl:Picture15}, { imgUrl:Picture16}, { imgUrl:Picture17}
   ];
 
  return (
    <div className="main-page-wrapper p0">

     <div className="container mt-150">
      <div   style={{height:'250px', width:'100%', backgroundColor:"#0f1469", position:'relative', zIndex:2}}>
      
        <h2 className="ps-0 fsm-bold position-absolute text-white text-center" style={{marginTop:'100px', marginLeft:'40%', zIndex:5}}>About US</h2>
      </div>
        <div className="">
            
        
           <p className="mt-2 pt-4 text-dark  fw-bold " style={{color:'black !important', textAlign:'justify'}}>
            Founded in 2024 and Headquarter in UAE, JBS Global Solutions is focusing on Banking IT business solution and services. Company specialized in providing state of art Digital Banking solution – Retail, Corporate, onboarding and Family Banking, Instant Payment Solution (IPS), Testing services, AI based development, Business consulting, Staffing and dedicated Offshore Development centers (ODC) for Bank’s specific Application development and L1/L2 support In India city like Gurugram, Chennai and Bangalore.
           </p>
           <div className="col-12">
              
           </div>
        </div>
        <div className="row mt-5 pt-4">
          <div className="col-12">
            <h4 className="text-primary fw-bold pb-2">Our Approach</h4>

            <ul >
              <li className="text-dark" style={{textAlign:'justify'}}><span><GoDotFill size={15} color="black"  /></span> &nbsp; &nbsp;Client-focused, Collaborative partnership with Banks and our Solutions partners.</li>
              <li className="text-dark" style={{textAlign:'justify'}}><span><GoDotFill size={15} color="black"  /></span> &nbsp; &nbsp;Emphasis on innovation, quality, and efficiency. </li>
              <li className="text-dark" style={{textAlign:'justify'}}><span><GoDotFill size={15}color="black"  /></span> &nbsp; &nbsp;Proven methodologies and frameworks that ensure timely and cost-effective project delivery.</li>
            </ul>
         
          </div>
        </div>
        <hr className="mt-5  mb-5"/>

        <div className="row mt-5 pt-4">
          <div className="col-12">
            <h4 className="text-primary fw-bold  pb-2">Why JBS Global Solutions? </h4>

            <ul >
              <li className="text-dark" style={{textAlign:'justify'}}><span><GoDotFill size={15} color="black"  />
              </span> &nbsp; &nbsp;Trusted Partner, Skilled team with extensive industry experience.</li>
              <li className="text-dark" style={{textAlign:'justify'}}><span><GoDotFill size={15} color="black"  />
              </span> &nbsp; &nbsp;Emphasis on flexibility and adaptability.. </li>
              <li className="text-dark" style={{textAlign:'justify'}}><span><GoDotFill size={15}color="black"  />
              </span> &nbsp; &nbsp;High standards of professionalism and transparency.</li>
            </ul>
         
          </div>
        </div>
        <hr className="mt-5  mb-5"/>

        <div className="row mt-5 pt-4">
          <div className="col-12">
            <h4 className="text-primary fw-bold  pb-2">Our Services </h4>

            <ul className="">
              <li className="text-darks " style={{textAlign:'justify'}}><span><GoDotFill size={15} color="black"  />
              </span> &nbsp; &nbsp;Product Development - End-to-end product design and development services.</li>
              <li className="text-darks" style={{textAlign:'justify'}}><span><GoDotFill size={15} color="black"  />
              </span> &nbsp; &nbsp;Expertise in turning concepts into market-ready solutions. </li>
              <li className="text-darks" style={{textAlign:'justify'}}><span><GoDotFill size={15}color="black"  />
              </span> &nbsp; &nbsp;Agile methodologies to accelerate product launch.</li>
              <li className="text-darks" style={{textAlign:'justify'}}><span><GoDotFill size={15}color="black"  />
              </span> &nbsp; &nbsp;Resource Augmentation - Flexible staffing solutions tailored to project needs.</li>
              <li className="text-darks" style={{textAlign:'justify'}}><span><GoDotFill size={15}color="black"  />
              </span> &nbsp; &nbsp;Access to skilled professionals in various technical and managerial roles.</li>
               <li className="text-darks" style={{textAlign:'justify'}}><span><GoDotFill size={15}color="black"  />
              </span> &nbsp; &nbsp; Scalable resource models that adapt to project demands.</li>
              <li className="text-darks" style={{textAlign:'justify'}}><span><GoDotFill size={15}color="black"  />
              </span> &nbsp; &nbsp;Testing Services - Comprehensive testing frameworks to ensure product.</li>
            </ul>
         
          </div>
        </div>
        <hr className="mt-5  mb-5"/>
     </div>

      {/* <Helmet>
        <title>
          About Event Organizer || Deski-Saas & Software React Template
        </title>
      </Helmet> */}
      {/* End Page SEO Content */}

      {/* <Header /> */}
      {/* End HeaderFour */}

      {/* =============================================
				Fancy Text block Twenty
			============================================== */}
      {/* <div className="fancy-text-block-twenty">
        <img
          src="images/shape/124.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/125.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <div className="container">
          <h1 className="title font-slab">About us</h1>
          <div className="fancy-video-box-one mb-130 md-mb-70">
            <FancyVideoOne />
          </div>


          <div className="row">
            <div
              className="col-lg-5"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="client-info font-rubik">
                <span>About Us</span>
              </div>
              <div className="title-style-five">
                <p>
                  <span>Founded in 2024 and Headquarter in UAE, JBS Global Solutions is focusing on Banking IT business solution and services. Company specialized in providing state of art Digital Banking solution – Retail, Corporate, onboarding and Family Banking, Instant Payment Solution (IPS), Testing services, AI based development, Business consulting, Staffing and dedicated Offshore Development centers (ODC) for Bank’s specific Application development and L1/L2 support In India city like Gurugram, Chennai and Bangalore.
                  </span>
                
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 ms-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <AboutTabs />
            </div>
          </div>
        </div>
      </div> */}
      {/* /.fancy-text-block-twenty */}

      {/* =====================================================

      {/* /.counter-with-icon-one */}

      {/* =============================================
				Team Section
			==============================================  */}
      {/* <div className="team-section-four mt-250 md-mt-150">
        <img
          src="images/shape/129.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/130.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <div className="container">
          <div className="title-style-five text-center mb-90 md-mb-60">
            <h6>Management Team</h6>
            <h2>The team behind deski </h2>
          </div>

          <div className="team-wrapper">
            <div className="row">
              <TeamFive />
            </div>
            <img
              src="images/shape/126.svg"
              alt="shape"
              className="shapes shape-one"
            />
          </div>
       
        </div>
      </div> */}


      {/* 
     =============================================
				Fqa Text Block
		=========================management team ===================== */}
      <div className="fancy-text-block-six mt-50 pt-50 md-mt-130">
        <div className="container">
          <div className="row align-items-center">
            
          <div
              className="col-lg-6 col-md-8 m-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="illustration-holder d-flex justify-content-end md-mt-50 p-3" >
                <img src={ceo} alt="media" className="p-lg-5 p-3 "  />
             
           
        
              </div>
              {/* /.illustration-holder */}
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="title-style-five mb-35">
                <h3 className="text-primary">Management Team</h3>
            
              </div>
              {/* End title */}
              <Faq />
            </div>

          </div>
        </div>
      </div>
      {/* ---------------------section -why chooses */}
      <div className="fancy-text-block-six mt-100 pt-50 md-mt-130">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="title-style-five mb-35">
                <h6>Why choose us</h6>
                <h2>
                  <span>Why you should</span> choose us?
                </h2>
              </div>
              {/* End title */}
              <FaqClassicThree />
           
            </div>

            <div
              className="col-lg-6 col-md-8 m-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="illustration-holder d-flex justify-content-end md-mt-50">
                <img src="images/media/img_58.png" alt="media" />
                <img
                  src="images/shape/113.svg"
                  alt="media"
                  className="shapes shape-one"
                />
              </div>
           
            </div>
          </div>
        </div>
      </div>
      {/* /.why should you choose us */}


      {/* <div className="counter-with-icon-one border-style pt-120 pb-45 md-pt-70">
        <div className="container">

          <div className="row bg-info p-3" style={{minHeight:'60vh',height:'auto'}}> 
            <div className="col-lg-5 col-12 d-flex align-items-center">
              <div className="d-flex justify-content-center ">
                <h2 className="fw-bold">15+ years of award-winning digital banking experience
                </h2>
              </div>
            </div>
            <div className="col-lg-7 col-12 bg-white">
             <div className="row">
              {imageNames.map((image, index) => (
                <div key={index} className="col-4 mb-3"> 
                  <div className="border border-gray p-2">
                    <img
                      src={image.imgUrl} 
                      alt={image.imgUrl}
                      className="img-fluid"
                      style={{ height: '80px', width: '100%', objectFit: 'contain' }}
                    />
                  </div>
                </div>
              ))}
            </div>
            </div>
          </div>
  
         </div>
      </div> */}


   
    </div>
  );
};

export default EventOrganizer;
