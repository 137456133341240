import React from "react";
import { CgEditBlackPoint } from "react-icons/cg";


const Faq = () => {
  return (
    <div className="accordion-style-two pe-5">
      <div className="faq-wrraper">
        <div className="row pb-2">
         <div className="col-1"><CgEditBlackPoint  size={15} color="blue" /> </div>
         <div className="col-11 ps-0">
           <p className="pt-0 pb-2 text-dark ps-3" style={{textAlign:'justify'}}>
            <span className="text-primary pe-3">Amit,</span> is a visionary fintech leader & driving force behind JBS Global Solutions. He is an innovative leader with an overall 30+ years in the IT industry with global experience, creating diversified businesses across multiple sectors through innovative use of technology for BFSI, Manufacturing, Telecom and IT/ITeS industries. </p>
         </div>
        </div>
        <div className="row pb-2">
         <div className="col-1"><CgEditBlackPoint  size={15} color="blue" /> </div>
         <div className="col-11 ps-0">
           <p className="pt-0 pb-2 text-dark ps-3" style={{textAlign:'justify'}}>
           He has given immense contribution in the growth story of BFSI product company Intellect Design Arena and served as an EVP and Partner of Intellect Design Arena. Additional role played as Board of Director for Dubai and Thailand entities.
            </p>
         </div>
        </div>
        <div className="row pb-2">
         <div className="col-1"><CgEditBlackPoint  size={15} color="blue" /> </div>
         <div className="col-11 ps-0">
           <p className="pt-0 pb-2 text-dark ps-3" style={{textAlign:'justify'}}>
           Headed several product lines:- Cash Management (DTB), Trade and SCF (iColumbis.AI).  iGTB product ranked #1 during his tenure for the 5th consecutive year in Transaction Banking.
            </p>
         </div>
        </div>
        <div className="row pb-2">
         <div className="col-1"><CgEditBlackPoint  size={15} color="blue" /> </div>
         <div className="col-11 ps-0">
           <p className="pt-0 pb-2 text-dark ps-3" style={{textAlign:'justify'}}>
           He has been playing an active role for the past 2+ years as Growth Advisor to the Board of director and technology team of FSM Global Technologies to improve the overall offering and revenue for FSM grid product into the global market.
            </p>
         </div>
        </div>
        <div className="row pb-2">
         <div className="col-1"><CgEditBlackPoint  size={15} color="blue" /> </div>
         <div className="col-11 ps-0 ps-0">
           <p className="pt-0 pb-2 text-dark ps-3" style={{textAlign:'justify'}}>
           He has worked with fortune 500 companies like IBM, GE Money, Whirlpool and Electrolux.
            </p>
         </div>
        </div>
  
      
       
       {/* <p className="pt-0 pb-2  text-dark" style={{textAlign:'justify'}}><span className="text-primary pe-3"><CgEditBlackPoint size={15} color="blue" />&nbsp; </span>Headed several product lines:- Cash Management (DTB), Trade and SCF (iColumbis.AI).  iGTB product ranked #1 during his tenure for the 5th consecutive year in Transaction Banking.</p>    */}
       {/* <p className="pt-0 pb-2  text-dark" style={{textAlign:'justify'}}><span className="text-primary pe-3"><CgEditBlackPoint size={15} color="blue" />&nbsp; </span>He has been playing an active role for the past 2+ years as Growth Advisor to the Board of director and technology team of FSM Global Technologies to improve the overall offering and revenue for FSM grid product into the global market.</p>    */}
       {/* <p className="pt-0 pb-2  text-dark" style={{textAlign:'justify'}}><span className="text-primary pe-3"><CgEditBlackPoint size={15} color="blue" />&nbsp; </span>He has worked with fortune 500 companies like IBM, GE Money, Whirlpool and Electrolux.</p>    */}
    
      </div>
    </div>
  );
};

export default Faq;
