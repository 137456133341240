import React from "react";



const Features = () => {
  return (
    <div className="row">
      <div className="col-xl-6 col-lg-7 order-lg-last" data-aos="fade-left">
        <div className="ps-lg-5">
          <div className="title-style-fourteen">
            <div className="upper-title">Who we are</div>
            <h4 className="mt-0 mb-0 text-primary">Singaporean Fintech: </h4>
            <p className="pt-0 pb-0">Headquartered in Singapore since 2004, with hubs in India and Philippines  </p>
            <h4 className="mt-0 mb-0 text-primary">State-of -Art Digital Banking Platform and Products: </h4>
            <p className="pt-0 pb-0">Tagit offers an open API platform, and prepackaged products: Retail and Corporate Banking, Digital Onboarding and Family Banking.  Built on the Mobeix Platform these products are micro-services based and cloud native offering clients highly scalable and secure applications for all </p>
            <h4 className="mt-0 mb-0 text-primary">Digital Banking Specialists: </h4>
            <p className="pt-0 pb-0">Staff strength of 350+ with 90% engineering talent with  core development expertise in digital banking products and digital transformation projects.
            </p>

          </div>
      
        </div>
      </div>
      {/* Emd .col */}

      <div
        className="col-xl-6 col-lg-5 text-center text-lg-left order-lg-first d-flex justify-content-center align-items-center"
        data-aos="fade-right"
        style={{backgroundColor:'#6c757d', zIndex:2}}
      >
        <div className="illustration-holder d-inline-block md-mt-60" >

           <img
            src="https://img.freepik.com/free-vector/white-product-podium-with-green-tropical-palm-leaves-golden-round-arch-green-wall_87521-3023.jpg" alt="dummy image" 
            style={{height:'300px', width:'100%', zIndex:5}}
          />
        </div>
      </div>
      {/* End .col */}
    </div>
  );
};

export default Features;
