import React from "react";
import { Link } from "react-router-dom";
import Social from "../../../../components/social/Social";
import AboutFour from "../../../../components/about/AboutFour";
import CallToActionThree from "../../../../components/call-to-action/CallToActionThree";
import { GoDotFill } from "react-icons/go";
import {aboutBg, retailer, Prod1, Prod2, Prod3, Prod4, Personalized, RetailBanner1, rt2, support, insight, signature, comprehensive, customeAuthentication, sumart_money, Family_Mobile, silent, parent, child, seniors} from "../../pages/ImagePath"



const RetailerBanking = () => {
  return (
    <div className="main-page-wrapper p0">
 
 <div className="container-fluid mt-150 ps-0 pe-0">
      <div   style={{height:'350px', width:'100%', position:'relative', zIndex:2}}>
         <img src={RetailBanner1} alt=""  style={{width:'100%', height:'100%'}}  />
   

      </div>
      {/* <div   style={{height:'500px', width:'100%', position:'relative', zIndex:2}}>
         <img src={retailer} alt=""  style={{width:'100%', height:'350px'}}  />
         <div className="position-absolute" style={{marginTop:'4%', marginLeft:'4%', zIndex:5}}>
         <h2 className="ps-0 fsm-bold  text-danger text-center" >MOBEIX RETAIL BANKING</h2>
         <h4 className="text-primary ">Innovative Digital Experiences, Delivered.</h4>
         </div>

      </div> */}

     
     </div>

      {/* 	=============================================
				Fancy Hero Three
			============================================== */}
      <div className="fancy-hero-three bg-transparent">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h4 className="text-primary fw-bold pb-2"   >Deliver Next-Generation Banking Solutions, Today</h4>
                <p className="text-dark text-justify" style={{textAlign:'justify'}}>
                The retail banking industry is being disrupted by digital technology and FinTechs. As customers accelerate the use of digital channels, banks need to develop new business models so that they can attract and retain profitable customers. Banks that succeed will leverage digital technologies to build a deeper engagement with their customers, understanding their needs and providing frictionless financial services – when and where their customers want it.
                </p>
          
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>
      {/* /.fancy-hero-three */}

      {/* 	=============================================
				Fancy Feature Seven
			==============================================  */}
      <div className="fancy-feature-seven">
        <div className="container">
          <div className="title-style-two text-center mb-150 md-mb-70">
     
            <h4 className="fw-bold pb-0 mb-0 text-primary">
            Delight Customers with a Personalised Omnichannel Experience
              <span>
            
                <img src="images/shape/line-shape-8.svg" alt="line shape" />
              </span>{" "}
             
            </h4>
          </div>
          {/* /.title-style-two */}

          <div className="block-wrapper">
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src={Prod4} alt="illustrator" style={{objectFit:'fill', height:'80%',width:'80%'}} />
                    {/* <img src="images/assets/ils_04.svg" alt="illustrator" /> */}
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <p className="font-rubik pb-2" style={{textAlign:'justify'}}>
                     Mobeix Retail Banking allows banks to seamlessly engage consumers across different channels – mobile, web, social media, voice and deliver digital banking services from onboarding, advanced biometrics, account management and payment capabilities. Leveraging AI technologies and push notifications, banks can deliver personalized and context-based financial services to consumers on the move.
                    </p>
                    <p className="font-rubik pb-2" style={{textAlign:'justify'}}>
                    Built on a component-based architecture, banks can leverage the rich set of banking capabilities built in the platform as well as extend and build their own banking services by using a rich set of development tools.
                    </p>
                    <p className="font-rubik pb-2" style={{textAlign:'justify'}}>
                     Mobeix Retail Banking has helped banks around the world achieve significant business growth through the digital channels and they have been recognized as digital leaders winning numerous awards globally.
                    </p>
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                {/* <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/ils_05.svg" alt="illustrator" />
                  </div>{" "}
             
                </div> */}
                <div
                  className="col-lg-12 order-lg-first bg-white p-4"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    
                    <h4 className=" " style={{textAlign:'justify'}}>Mobeix Corporate Banking Features</h4>
                    <div className="row mt-4">
                        <div className="d-flex justify-content-center align-items-center p-4">
                            <img src={insight} alt=" " style={{objectFit:'fill', height:'80%', width:'80%'}} />
                        </div>
                      </div>

                    <h4 className=" " style={{textAlign:'justify'}}>
                     Tagit’s omnichannel (Internet and Mobile) out-of-the-box digital retail banking product provides a base of more than 100+ retail digital banking features that can be deployed quickly under 4-6 months with real-time integration to the bank’s back-end and host systems
                    </h4>
                   
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}


            {/* /.block-style-nine */}
            <div className="block-style-nine mt-5">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src={Prod4} alt="illustrator"  style={{width:'100%', height:'70%'}}/>
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h4 className="text-primary fw-bold">Mobeix Retail Banking Features</h4>
                    <h6 className="text-dark pb-2">
                    Customer Onboarding & eKYC
                    </h6>
                    <p className="font-rubik" style={{textAlign:'justify'}}>
                    Customer onboarding via multiple channels from a single platform, including mobile and internet banking.​
                    </p>
                    <p className="font-rubik" style={{textAlign:'justify'}}>
                    Automated KYC operations, including document validation, OCR, ID and Face matching, liveness detection and more.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder d-flex justify-content-center">
                    <img src={Prod4} alt="illustrator" />
                    {/* <img src="images/assets/ils_07.svg" alt="illustrator" /> */}
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h4 className="text-primary fw-bold">Transactions</h4>
               
                    <p className="font-rubik" style={{textAlign:'justify'}}>
                    Support for all types of transactions, including fund transfers, P2P, bill payments, top-ups, cardless ATM, and loan payments.
                        One-click transactions, whereby saved transaction details can be reused for quicker payments.
                        Standing instructions for scheduling payments and transfers.
                    </p>
                    <p className="font-rubik" style={{textAlign:'justify'}}>
                    One-click transactions, whereby saved transaction details can be reused for quicker payments.
                    </p>
                    <p className="font-rubik" style={{textAlign:'justify'}}>
                    Standing instructions for scheduling payments and transfers.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
          </div>
        </div>
      </div>
      {/* /.fancy-feature-seven */}

      {/* =====================================================
				Fancy Text Block Thirteen
			===================================================== */}
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-two">
                  <h4 className="fw-bold text-primary"> Personalised Dashboard.</h4>
                </div>
                <p className="text-dark" style={{textAlign:'justify'}}> Customers can personalise settings based on their preferences across each channel. </p>
                <p className="text-dark" style={{textAlign:'justify'}}> Tailored recommendations such as saving goals or suggested spending based on individual insights. </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <img src={Prod2} alt="feature" />
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
      {/* /.fancy-text-block-thirteen */}

    </div>
  );
};

export default RetailerBanking;
